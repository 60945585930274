@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #03577a #f3f4f6;
  border-radius: 30x;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  border-radius: 30x;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #03577a;
  border-radius: 30px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f3f4f6;
  border-radius: 30x;
}

.filter-section h3 {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.background-text {
  font-size: calc(2vw + 20px); /* Default font size scaling */
  font-weight: bold;
  color: transparent;
  -webkit-text-stroke: 1px rgba(255, 0, 0, 0.2); /* Light red stroke */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  z-index: 1;
}

.foreground-text {
  font-size: calc(1.5vw);
  font-weight: bold;
  color: red;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

/* Responsive Adjustments */

/* For screens between 1024px and 1440px */
@media (min-width: 1024px) and (max-width: 1440px) {
  .background-text {
    font-size: 53px;
  }
  .foreground-text {
    font-size: 28px;
    top: 60%;
  }
}

/* For screens between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .background-text {
    font-size: 45px;
  }
  .foreground-text {
    font-size: 23px;
    top: 70%;
  }
}

/* For screens between 625px and 768px */
@media (min-width: 625px) and (max-width: 768px) {
  .background-text {
    font-size: 40px;
  }
  .foreground-text {
    font-size: 23px;
    top: 70%;
  }
}

/* For screens between 425px and 625px */
@media (min-width: 425px) and (max-width: 625px) {
  .background-text {
    font-size: 35px;
  }
  .foreground-text {
    font-size: 17px;
    top: 70%;
  }
}

/* For screens between 325px and 425px */
@media (min-width: 325px) and (max-width: 425px) {
  .background-text {
    font-size: 28px;
  }
  .foreground-text {
    font-size: 14px;
    top: 60%;
  }
}

/* For screens below 325px */
@media (max-width: 325px) {
  .background-text {
    font-size: 24px;
  }
  .foreground-text {
    font-size: 12px;
    top: 70%;
  }
}

/* Custom Styles for Slider */
.slider-item img {
  max-width: 100%;
  height: auto;
}

.slider-item p {
  font-size: 1rem;
}

@media (min-width: 640px) {
  .slider-item p {
    font-size: 1.25rem;
  }
}

.slick-prev:before {
  display: none;
}

/* For screens between 320px and 425px */
@media (min-width: 320px) and (max-width: 425px) {
  .ic_ambulance {
    width: 80px;
    height: 60px;
  }

  .login_icon {
    font-size: 33px;
    color: #03577a; /* Adjust as per your primary color */
  }
}

/* For screens between 426px and 625px */
@media (min-width: 426px) and (max-width: 625px) {
  .ic_ambulance {
    width: 70px;
    height: 50px;
  }

  .login_icon {
    font-size: 32px;
    color: #03577a; /* Adjust as per your primary color */
  }
}

/* For screens between 626px and 768px */
@media (min-width: 626px) and (max-width: 768px) {
  .ic_ambulance {
    width: 55px !important;
    height: 60px;
  }

  .login_icon {
    font-size: 35px !important;
    color: #03577a; /* Adjust as per your primary color */
  }
}

/* For screens between 768px and 1023px */
@media (min-width: 768px) and (max-width: 1023px) {
  .ic_ambulance {
    width: 60px;
    height: 70px;
  }

  .login_icon {
    font-size: 37px;
    color: #03577a;
  }
}

@keyframes zoom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.animate-zoom {
  animation: zoom 1.5s infinite ease-in-out;
}
